import React from "react"
import { connect } from "react-redux"
import { Layout } from "../../components/Layout"
import { AnalyticsSection } from "../../style/analytics"
import { AnalyticsTab } from "./analyticsTab";
import { AnalyticsDisplayContainer } from "./analyticsDisplayContainer";

function AnalyticsContainer({
    selectedTab,
}) {
    return (
        <Layout>
            <AnalyticsSection>
                <AnalyticsTab activeTab={selectedTab} />
                <AnalyticsDisplayContainer selectedTab={selectedTab} />
            </AnalyticsSection>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedTab: state.analytics.selectedTab
    }
}

export default connect(mapStateToProps)(AnalyticsContainer);
